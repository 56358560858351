<template>
  <base-page
    :title="$t('companyManage.title')"
    :formData="formData"
    :config="config"
    @functionMethod="functionMethod"
    @search="search"
  >
    <table-list
      ref="tableList"
      :url="$api.companyFind"
      :defaultParams="params"
      :columns="columns"
      :multiple="true"
      :highlightCurrentRow="false"
      v-model="tableRows"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
      <!-- 行业 -->
      <template v-slot:industry="{ row }">
        <!-- lang:行业 -->
        {{
          row.industry
            ? $util.getTreeNames(row.industry, 'value')
            : row.customIndustry || ''
        }}
      </template>
      <!-- 地址 -->
      <template v-slot:address="{ row }">
        <!-- lang:地址 -->
        {{
          row.address
            ? $util.getTreeNames(row.address, 'value') +
              (row.addressDetail ? '/' + row.addressDetail : '')
            : row.addressDetail || ''
        }}
      </template>
      <!-- 发票信息 -->
      <template v-slot:option="{ row }">
        <el-button
          v-if="$page.showBtn('COMPANY_INVOICE')"
          type="text"
          @click="openInvoice(row)"
        >
          <!-- lang:发票信息 -->
          {{ $t('functionEnum.COMPANY_INVOICE') }}
        </el-button>
        <!-- <el-button
          v-if="$page.showBtn('COMPANY_BALANCE')"
          type="text"
          @click="openRemainAmount(row)"
        >

          {{ $t('functionEnum.COMPANY_BALANCE') }}
        </el-button> -->
      </template>
    </table-list>
    <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
    <invoice v-if="showInvoice" :tableRow="tableRow" @closePage="closePage" />
    <remain-amount
      v-if="showRemainAmount"
      :tableRow="tableRow"
      @closePage="showRemainAmount = false"
    />
    <dialog-import
      ref="dialogImport"
      v-if="showDialogImport"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.companyImportFromExcel"
      :defaultParams="importFormData"
      :defineDeal="true"
      :template="importTemplate"
      @cancel="showDialogImport = false"
      @confirm="importConfirm"
    >
      <!-- 付费模式 -->
      <form-list
        style="padding-top: 20px"
        ref="formList"
        :formData="importFormData"
        :config="importConfig"
      />
    </dialog-import>
  </base-page>
</template>

<script>
import Detail from './detail'
import Invoice from './invoice'
import RemainAmount from './remainAmount'
import { importFile } from '@/utils/ajax'

export default {
  components: {
    Detail,
    Invoice,
    RemainAmount,
  },
  data() {
    const lang = this.$util.getLocalStorage('lang')
    return {
      importTemplate: `/template/${lang}/company.xlsx`,
      // 搜索
      formData: {},
      config: [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 8,
          tagProps: {
            placeholder: this.$t('companyManage.nameOrNotes'),
          },
        },
        {
          tag: 'el-cascader',
          label: this.$t('companyManage.industry'),
          prop: 'industryId',
          span: 8,
          tagProps: {
            options: [],
            props: {
              label: 'value',
              value: 'id',
            },
          },
        },
        {
          tag: 'el-cascader',
          label: this.$t('companyManage.address'),
          prop: 'addressId',
          span: 8,
          tagProps: {
            options: [],
            props: {
              label: 'value',
              value: 'id',
            },
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 8,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 表格页
      tableRow: null,
      tableRows: [],
      params: {
        needFullIndustry: 'YES',
        needFullAddress: 'YES',
      },
      columns: [
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
        // lang:企业名称
        {
          label: this.$t('companyManage.name'),
          prop: 'name',
        },
        // lang:行业
        {
          label: this.$t('companyManage.industry'),
          prop: 'industry',
        },
        // lang:地址
        {
          label: this.$t('companyManage.address'),
          prop: 'address',
        },
        // lang:联系人
        {
          label: this.$t('companyManage.contacts'),
          prop: 'contacts',
        },
        // lang:联系方式
        {
          label: this.$t('companyManage.contactsInformation'),
          prop: 'contactsInformation',
        },
        // lang:邮箱
        {
          label: this.$t('companyManage.email'),
          prop: 'email',
        },
        // lang:是否可以创建下级企业
        {
          label: this.$t('companyManage.canAddSubCompany'),
          prop: 'canAddSubCompany',
          callback: (row) => {
            const obj = this.$util.listToObj(this.$dict.yesOrNo())
            return obj[row.canAddSubCompany || 'NO']
          }
        },
        // lang:业务描述
        {
          label: this.$t('companyManage.businessScope'),
          prop: 'businessScope',
        },
        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
          width: '200px',
          align: 'center',
        },
      ],
      // 表单页
      showDetail: false,
      // 发票
      showInvoice: false,
      // 导入
      showDialogImport: false,
      importFormData: {},
      importConfig: [
        // lang:可用计费模式
        {
          tag: 'el-select',
          label: this.$t('companyManage.feeModeStr'),
          prop: 'feeModeStr',
          hidden: true,
          rules: {
            noNull: true,
          },
          tagProps: {
            multiple: true,
            options: [],
          },
        },
        // lang:计费模式
        {
          tag: 'el-select',
          label: this.$t('companyManage.feeMode'),
          prop: 'feeMode',
          hidden: true,
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
          },
        },
      ],
      // 余额查看
      showRemainAmount: false,
    }
  },
  mounted() {
    const language = this.$util.getLocalStorage('lang')
    this.dictFindTree('COMPANY_INDUSTRY', language)
    this.dictFindTree('ADDRESS', language)
    this.countFeeMode()
  },
  methods: {
    // 打开余额详细
    openRemainAmount(row) {
      this.tableRow = row
      this.showRemainAmount = true
    },
    // 导入验证和导入成功回掉
    importConfirm(isImported) {
      if (isImported) {
        this.showDialogImport = false
        this.search()
      } else {
        this.$refs.formList.$refs.formList.validate((valid) => {
          if (!valid) return
          this.$refs.dialogImport.import()
        })
      }
    },
    // 根据当前企业层级判断计费模式
    countFeeMode() {
      const { company,productConfigEntities } = this.$store.getters
      // const { level, feeModeStr, feeMode } = company
      const { level } = company
      let feeModeStr = []
      let feeMode = []
      productConfigEntities.forEach(item =>{
        feeModeStr.push(item.feeModeStr)
        feeMode.push(item.feeMode)
      })
      // 顶级企业管理员建TMVA企业时，选择可用计费模式
      // TMVA企业管理员建OEM企业时，选择当前计费模式
      // OEM企业及以下继承
      const resourceFeeModeEnum = this.$dict.resourceFeeModeEnum()
      if (level === 'ONE') {
        this.importConfig[0].hidden = false
        this.importConfig[0].tagProps.options = resourceFeeModeEnum
      } else if (level === 'TWO') {
        this.importConfig[1].hidden = false
        this.importConfig[1].tagProps.options = resourceFeeModeEnum.filter(
          (item) => feeModeStr.indexOf(item.value) >= 0
        )
      } else {
        this.importConfig[1].hidden = false
        this.importConfig[1].tagProps.disabled = true
        this.importConfig[1].tagProps.options = resourceFeeModeEnum
        this.importFormData.feeMode = feeMode
      }
    },
    // 查询字典
    dictFindTree(dictType, language) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dictFindTree,
        data: {
          dictType,
          language,
        },
      }).then((data) => {
        console.log(data)
        if (dictType === 'COMPANY_INDUSTRY') {
          this.config[1].tagProps.options = data || []
        } else {
          this.config[2].tagProps.options = data || []
        }
      })
    },
    // 关闭详情页回调
    closePage(method) {
      this.showDetail = false
      this.showInvoice = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      const formData = this.$util.copyData(this.formData)
      console.log(formData)
      formData.industryId = formData.industryId.pop()
      formData.addressId = formData.addressId.pop()
      this.$refs.tableList.searchList(formData, isActiveQuery)
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (method !== 'add' && method !== 'import' && !this.tableRows.length) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      // 导出企业码
      if (method === 'export') {
        importFile({
          url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.companyExportCompanyCode,
          name: this.$util.format(new Date(), 'yyyy-MM-dd-hh-mm-ss'),
          type: '.zip',
          data: {
            companyIds: this.tableRows.map(item => item.id),
          },
        })
        return
      }
      if (this.tableRows.length > 1) {
        this.$element.showMsg(this.$t('base.justSelectOne'), 'warning')
        return
      }
      this.tableRow = this.tableRows[0]
      switch (method) {
        case 'add':
        case 'edit':
        case 'view':
          this.showDetail = true
          break
        case 'del':
          this.deleteMethod()
          break
        case 'import':
          this.showDialogImport = true
          break
        default: // do something
      }
    },
    // 删除
    deleteMethod() {
      this.$element
        .showConfirm({
          content: this.$t('base.deleteConfirm'),
        })
        .then(() => {
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.companyDelete,
            data: this.tableRow,
          }).then(() => {
            // lang:删除成功
            this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
            this.search()
          })
        })
    },
    // 打开发票弹框
    openInvoice(row) {
      this.$store.commit('updateCmd', 'invoice')
      this.$store.commit('updateDialogTitle', this.$t('companyManage.invoice'))
      this.tableRow = row
      this.showInvoice = true
    },
  },
}
</script>
