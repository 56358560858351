<template>
  <dialog-form-list
    :config="config"
    :formData="formData"
    @cancel="cancel"
    @confirm="confirm"
  />
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    const self = this
    return {
      cmd: this.$store.state.cmd,
      formData: {},
      config: [
        // lang:发票类型
        {
          tag: 'el-select',
          label: this.$t('companyManage.invoiceType'),
          prop: 'invoiceType',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.invoiceType(),
          },
          tagEvents: {
            change(type) {
              const hidden = type === 'GENERAL' ? true : false
              self.config[3].hidden = hidden
              self.config[4].hidden = hidden
              self.config[5].hidden = hidden
              self.config[6].hidden = hidden
            },
          },
        },
        // lang:开票企业名称
        {
          label: this.$t('companyManage.companyNameForInvoice'),
          prop: 'companyNameForInvoice',
          rules: {
            noNull: true,
          },
        },
        // lang:统一社会信用代码
        {
          label: this.$t('companyManage.creditCodeForInvoice'),
          prop: 'creditCodeForInvoice',
          rules: {
            noNull: true,
          },
        },
        // lang:开户行名称
        {
          label: this.$t('companyManage.openAccountBankNameForInvoice'),
          prop: 'openAccountBankNameForInvoice',
          hidden: true,
          rules: {
            noNull: true,
          },
        },
        // lang:开户行账号
        {
          label: this.$t('companyManage.openAccountBankAccountForInvoice'),
          prop: 'openAccountBankAccountForInvoice',
          hidden: true,
          rules: {
            noNull: true,
          },
        },
        // lang:开户电话
        {
          label: this.$t('companyManage.openAccountTelNumForInvoice'),
          prop: 'openAccountTelNumForInvoice',
          hidden: true,
          rules: {
            noNull: true,
            type: 'mobileOrPhone',
          },
        },
        // lang:开户地址
        {
          label: this.$t(
            'companyManage.openAccountBankAddressAddressForInvoice'
          ),
          prop: 'openAccountBankAddressAddressForInvoice',
          hidden: true,
          rules: {
            noNull: true,
          },
          tagProps: {
            type: 'textarea',
            rows: 2,
          },
        },
      ],
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        const invoice = this.tableRow.invoice || {}
        invoice.invoiceType = invoice.invoiceType || 'GENERAL'
        const hidden = invoice.invoiceType === 'GENERAL' ? true : false
        this.config[3].hidden = hidden
        this.config[4].hidden = hidden
        this.config[5].hidden = hidden
        this.config[6].hidden = hidden
        Object.assign(this.formData, invoice)
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.companySetInvoice,
        data: this.formData,
      }).then(() => {
        // lang:发票信息设置成功
        this.$element.showMsg(
          this.$t('companyManage.setInvoiceSuccess'),
          'success'
        )
        this.$emit('closePage', 'invoice')
      })
    },
  },
}
</script>