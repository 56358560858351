<template>
  <base-dialog
    ref="baseDialog"
    :title="$t('companyManage.remainAmountSee')"
    @cancel="cancel"
    :showConfirm="false"
  >
    <table-list
      :isDefine="true"
      :showSort="false"
      :columns="columns"
      :defaultContent="defaultContent"
    >
    </table-list>
  </base-dialog>
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      // 授权数量
      columns: [
        {
          label: this.$t('assignDistribute.assignName'),
          prop: 'assignName',
        },
        {
          label: this.$t('companyManage.remainAmount'),
          prop: 'remainNumber',
        },
      ],
      defaultContent: [],
    }
  },
  mounted() {
    this.getDefaultContent()
  },
  methods: {
    // 获取授权内容
    getDefaultContent() {
      const { level, feeModeStr, feeMode, serviceResource = {} } = this.tableRow
      console.log(this.tableRow)
      let showFeeMode = ''
      if (level === 'ONE' || level === 'TWO') {
        if (level === 'ONE') {
          console.log('1')
          showFeeMode = 'METERING,ACCOUNT_SUBSCRIPTIONS'
        } else {
          console.log('2')
          showFeeMode = feeModeStr
        }
      } else {
        console.log('3')
        showFeeMode = feeMode
      }
      console.log(showFeeMode)
      const {
        remainAccountNumber,
        remainVideoCallDuration,
        remainAccountSubscriptionsNumber,
        remainEquipmentNumber,
        remainEquipmentComponentsNumber,
        remainArDigitalDisplayNumber,
        remainCloudRecordingStorageSpace,
        remainOssStorageSpace,
      } = serviceResource
      const defaultContent = this.$dict.resourceTypeEnum().map((item) => {
        let remainNumber = 0
        switch (item.value) {
          case 'ACCOUNT_NUMBER': // 账号数量(个)
            remainNumber = remainAccountNumber
            break
          case 'VIDEO_CALL_DURATION': // 通话时长(分钟)
            remainNumber = +parseFloat(remainVideoCallDuration / 60).toFixed(2)
            break
          case 'ACCOUNT_SUBSCRIPTIONS_NUMBER': // 账号订阅数量(个)
            remainNumber = remainAccountSubscriptionsNumber
            break
          case 'EQUIPMENT_NUMBER': // 设备码数量(个)
            remainNumber = remainEquipmentNumber
            break
          case 'EQUIPMENT_COMPONENTS_NUMBER': // 设备备件数量(个)
            remainNumber = remainEquipmentComponentsNumber
            break
          case 'AR_DIGITAL_DISPLAY_NUMBER': // AR数显数量(个)
            remainNumber = remainArDigitalDisplayNumber
            break
          case 'CLOUD_RECORDING_STORAGE_SPACE': // 云录制空间(G)
            remainNumber = +parseFloat(
              remainCloudRecordingStorageSpace / 1024 / 1024 / 1024
            ).toFixed(4)
            break
          case 'OSS_STORAGE_SPACE': // 云存储空间(G)
            remainNumber = +parseFloat(
              remainOssStorageSpace / 1024 / 1024 / 1024
            ).toFixed(4)
            break
          default: // do something
        }
        return {
          id: item.value,
          assignName: item.label,
          remainNumber: remainNumber,
          assignNumber: '',
          type: item.value,
        }
      })
      this.defaultContent = defaultContent.filter((item) => {
        if (
          showFeeMode.indexOf('METERING') >= 0 &&
          showFeeMode.indexOf('ACCOUNT_SUBSCRIPTIONS') >= 0
        ) {
          return true
        } else if (showFeeMode.indexOf('METERING') >= 0) {
          if (item.type !== 'ACCOUNT_SUBSCRIPTIONS_NUMBER') return true
        } else {
          if (item.type !== 'VIDEO_CALL_DURATION') return true
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
  },
}
</script>