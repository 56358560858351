<template>
  <dialog-form-list
    :config="config"
    :formData="formData"
    width="550px"
    @cancel="cancel"
    @confirm="confirm"
  />
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    const self = this
    return {
      cmd: this.$store.state.cmd,
      formData: {},
      currentCompany: null,
      config: [
        // lang:企业名称
        {
          label: this.$t('companyManage.name'),
          prop: 'name',
          rules: {
            noNull: true,
          },
          tagProps: {
            maxlength: 36,
            showWordLimit: true,
          },
        },
        // // lang:可用计费模式
        {
          tag: 'el-select',
          label: this.$t('companyManage.feeModeStr'),
          prop: 'feeModeStr',
          hidden: true,
          rules: {
            noNull: true,
          },
          tagProps: {
            multiple: true,
            options: [],
          },
        },
        // // lang:计费模式
        {
          tag: 'el-select',
          label: this.$t('companyManage.feeMode'),
          prop: 'feeMode',
          hidden: true,
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
          },
        },
        // lang:所属行业
        {
          tag: 'el-cascader',
          label: this.$t('companyManage.industry'),
          prop: 'industry',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
            props: {
              label: 'value',
              value: 'id',
            },
          },
          tagEvents: {
            change(ids) {
              if (ids[0] === 'OTHER') {
                self.config[4].hidden = false
              } else {
                self.config[4].hidden = true
              }
            },
          },
        },
        // lang:其他行业
        {
          label: this.$t('companyManage.customIndustry'),
          prop: 'customIndustry',
          hidden: true,
          rules: {
            noNull: true,
          },
        },
        // lang:联系地址
        {
          tag: 'el-cascader',
          label: this.$t('companyManage.address'),
          prop: 'address',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
            props: {
              label: 'value',
              value: 'id',
            },
          },
        },
        // lang:详细地址
        {
          label: this.$t('companyManage.addressDetail'),
          prop: 'addressDetail',
          rules: {
            noNull: true,
          },
          tagProps: {
            type: 'textarea',
            rows: 2,
          },
        },
        // lang:联系人
        {
          label: this.$t('companyManage.contacts'),
          prop: 'contacts',
          rules: {
            noNull: true,
          },
        },
        // lang:联系方式
        {
          label: this.$t('companyManage.contactsInformation'),
          prop: 'contactsInformation',
          rules: {
            noNull: true,
            type: 'mobileOrPhone',
          },
          tagProps: {
             maxlength: 11,
          },
        },
        // lang:邮箱
        {
          label: this.$t('companyManage.email'),
          prop: 'email',
          rules: {
            noNull: true,
            type: 'email',
          },
        },
        // 统一社会信用代码
        {
          label: this.$t('companyManage.creditCodeForInvoice'),
          prop: 'creditCodeForInvoice',
          rules: {
            noNull: true,
          },
          tagProps: {
            maxlength: 18,
          }
        },
        // lang:是否可以创建下级企业
        {
          tag: 'el-select',
          label: this.$t('companyManage.canAddSubCompany'),
          prop: 'canAddSubCompany',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.yesOrNo(),
          },
        },
        // lang:业务描述
        {
          label: this.$t('companyManage.businessScope'),
          prop: 'businessScope',
          rules: {
            noNull: true,
          },
          tagProps: {
            type: 'textarea',
            rows: 4,
          },
        },
      ],
    }
  },
  mounted() {
    this.countFeeMode()
  },
  methods: {
    // 根据当前企业层级判断计费模式
    countFeeMode() {
      const { company } = this.$store.getters
      this.currentCompany = company
      const { level, feeModeStr } = company
      // 顶级企业管理员建TMVA企业时，选择可用计费模式
      // TMVA企业管理员建OEM企业时，选择当前计费模式
      // OEM企业及以下继承
      console.log(company)
      // const resourceFeeModeEnum = this.$dict.resourceFeeModeEnum()
      // if (level === 'ONE') {
      //   this.config[1].hidden = false
      //   this.config[1].tagProps.options = resourceFeeModeEnum
      // } else if (level === 'TWO') {
      //   this.config[2].hidden = false
      //   this.config[2].tagProps.options = resourceFeeModeEnum.filter(
      //     (item) => feeModeStr.indexOf(item.value) >= 0
      //   )
      // } else {
      //   this.config[2].hidden = false
      //   this.config[2].tagProps.disabled = true
      //   this.config[2].tagProps.options = resourceFeeModeEnum
      // }
      this.setDefaultValue()
    },
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        const language = this.$util.getLocalStorage('lang')
        //默认值
        if (this.cmd !== 'add') {
          this.cacheFeeMode = this.tableRow.feeMode
          this.$util.setDefaultValue(this.formData, this.tableRow)
          // 语言处理
          this.dictFindTree(
            'COMPANY_INDUSTRY',
            this.tableRow.industry ? this.tableRow.industry.language : language
          )
          this.dictFindTree(
            'ADDRESS',
            this.tableRow.address ? this.tableRow.address.language : language
          )
          // 禁掉账号类型
          this.config[10].tagProps.disabled = true
          // 行业处理
          if (!this.tableRow.industry) {
            this.formData.industry = ['OTHER']
            this.config[4].hidden = false
          } else {
            this.formData.industry = this.$util.getTreeIds(
              this.tableRow.industry
            )
            this.config[4].hidden = true
          }
          // 地址处理
          this.formData.address = this.$util.getTreeIds(this.tableRow.address)
          console.log(this.formData.address)
          // 处理可用计费模式
          if (this.currentCompany.level === 'ONE') {
            if (this.tableRow.feeModeStr) {
              this.formData.feeModeStr = this.tableRow.feeModeStr.split(',')
            } else {
              this.formData.feeModeStr = []
            }
            this.config[1].tagProps.disabled = true
          }
        } else {
          this.dictFindTree('COMPANY_INDUSTRY', language)
          this.dictFindTree('ADDRESS', language)
          if (
            this.currentCompany.level !== 'ONE' &&
            this.currentCompany.level !== 'TWO'
          ) {
            this.formData.feeMode = this.currentCompany.feeMode
          }
        }
      })
    },
    // 查询字典
    dictFindTree(dictType, language) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dictFindTree,
        data: {
          dictType,
          language,
        },
      }).then((data) => {
        console.log(data)
        if (dictType === 'COMPANY_INDUSTRY') {
          data.push({
            id: 'OTHER',
            value: '其他',
          })
        }
        if (dictType === 'COMPANY_INDUSTRY') {
          this.config[3].tagProps.options = data || []
        } else {
          this.config[5].tagProps.options = data || []
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      // 处理行业
      const industry = formData.industry.pop()
      if (industry === 'OTHER') formData.industry = null
      else formData.industry = { id: industry }
      // 处理地址
      formData.address = { id: formData.address.pop() }
      // 处理计费模式
      const { level } = this.currentCompany
      delete formData.feeModeStr
      delete formData.feeMode
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'edit':
          if (level !== 'ONE' && this.cacheFeeMode !== formData.feeMode) {
            this.$element
              .showConfirm({
                content: this.$t('companyManage.feeModeChangeTips'),
              })
              .then(() => {
                this.editMethod(formData)
              })
          } else {
            this.editMethod(formData)
          }
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.companyAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$emit('closePage', 'add')
      })
    },
    // 修改
    editMethod(formData) {
      const tableRow = this.$util.copyData(this.tableRow)
      Object.assign(tableRow, formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.companyUpdate,
        data: tableRow,
      }).then(() => {
        // lang:修改成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$emit('closePage', 'edit')
      })
    },
  },
}
</script>